import React from "react"
import styled from "styled-components"

const SmallWidth = styled.main`
  ${"" /* width: 100%; */}
  height: auto;
  max-width: 1140px;
  margin: 0 auto;
  padding: 1.5rem 1.5rem;
  @media (max-width: 992px) {
    ${"" /* padding: 1rem 5%; */}
  }
`
export function Container({ children }) {
  return <SmallWidth>{children}</SmallWidth>
}
