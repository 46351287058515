import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"
import { accentTextColor, containerText, primaryOrange } from "../style/theme"

const BackButtonStyling = styled.div`
  width: 100%;
  text-align: ${props => props.align.toString()};
`

const Button = styled(Link)`
  font-family: Poppins;
  font-size: 3.125rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.52;
  letter-spacing: normal;
  text-align: center;
  color: ${props => (props.blackcolor ? containerText : accentTextColor)};
  cursor: pointer;
  margin-top: 1rem;
  text-decoration: none;

  &:hover {
    color: ${primaryOrange};
  }
`

/**
 *
 * Button that when pressed steps one step back in history
 *
 * @param {Boolean} blackcolor Boolean for inverted color
 * @param {String} align string for alignment of button
 */
export default function BackButton({ blackcolor, align = "center" }) {
  const pathArray = window.location.pathname.split("/")
  let backLocation = "/"

  if (pathArray.length > 3) {
    backLocation = pathArray.splice(0, pathArray.length - 1).join("/")
  } else {
    backLocation = [...pathArray.splice(0, pathArray.length - 1), "start"].join(
      "/"
    )
  }

  return (
    <BackButtonStyling align={align}>
      <Button blackcolor={blackcolor} to={backLocation}>
        ←
      </Button>
    </BackButtonStyling>
  )
}
BackButton.propTypes = {
  blackcolor: PropTypes.bool,
  align: PropTypes.string,
}
