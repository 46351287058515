import { Link, navigate } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { connect } from "react-redux"
import styled from "styled-components"
import { urlForCountry } from "../helpers/routing"
import {
  containerBackground,
  NavigationButton,
  NavigationButtonTextColor,
  primaryTitleColor,
} from "../style/theme"
import BackButton from "./backButton"

const Button = styled(Link)`
  all: initial;
  background: ${NavigationButton};
  color: ${NavigationButtonTextColor};
  border-radius: 2px;
  height: 125px;
  font-family: Poppins;
  font-weight: 600;
  font-size: 1.15rem;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 200ms ease-in;
  text-transform: capitalize;
  cursor: pointer;
  -webkit-font-smoothing: subpixel-antialiased;

  &:focus,
  :hover {
    background: ${primaryTitleColor};
  }
  &:active {
    background: #fafafa;
  }

  @media (min-width: 1919px) {
    height: 12vh;
  }

  @media (max-width: 600px) {
    height: 12vh;
  }

  ${({ smallNavigation }) =>
    smallNavigation &&
    `
      height: 3.4375rem;

      @media (min-width: 1919px) {
        height: 8vh;
      } 

      @media (max-width: 600px) {
        height: 8vh;
      }
  `};
`

const SectionContainer = styled.section`
  width: 100%;
  min-height: ${props => props.hight};
  background: ${containerBackground};
  position: relative;

  * {
    box-sizing: border-box;
  }
`

const SectionContainerInner = styled.div`
  margin: 0 1rem;
`

const FlexDivider = styled.div`
  width: 100%;
  height: 100%;
  max-width: 1140px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding: 2rem 0;
`

const SectionTitle = styled.div`
  width: 100%;
  text-align: center;
  -webkit-font-smoothing: subpixel-antialiased;
`

const NavigationContainer = styled.div``
const Title = styled.h1`
  font-family: Poppins;
  font-size: 2.5rem;
  font-weight: 600;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: ${primaryTitleColor};

  @media (max-width: 992px) {
    font-size: 2rem;
  }
`

const NavigationContainerInner = styled.div`
  height: 100%;
  max-width: 1140px;
  padding: 1rem 0;
  grid-template-columns: repeat(auto-fill, minmax(225px, 1fr));
  display: grid;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding: 5px;

  @media (max-width: 426px) {
    padding: 0px;
    width: 100%;
    flex-direction: column !important;
  }
`

/**
 *
 * Returns navigation based on the itmes array
 * Styling altered with strings from parents
 *
 * @param {Object} language redux object for language state
 * @param {Array} title translation
 * @param {Array} items menu items
 * @param {string} hight height variable
 * @param {String} smallNavigation show small view
 * @param {String} showBack show backbutton, used in lower subcategories
 */
const Navigation = ({
  language,
  title,
  items,
  hight = "60vh",
  smallNavigation = false,
  showBack = false,
}) => {
  return (
    <SectionContainer hight={hight}>
      <SectionContainerInner>
        <FlexDivider>
          <SectionTitle>
            <Title>{title[0].text}</Title>
          </SectionTitle>
          <NavigationContainer>
            <NavigationContainerInner>
              {items.map((i, index) => (
                <Button
                  key={`navigation-${index}`}
                  smallNavigation={smallNavigation}
                  to={urlForCountry(language.path, i.url[0].text)}
                >
                  {i.url_text[0].text}
                </Button>
              ))}
            </NavigationContainerInner>
            {showBack ? <BackButton /> : null}
          </NavigationContainer>
        </FlexDivider>
      </SectionContainerInner>
    </SectionContainer>
  )
}
Navigation.propTypes = {
  language: PropTypes.object,
  title: PropTypes.array.isRequired,
  items: PropTypes.array.isRequired,
  hight: PropTypes.string,
  smallNavigation: PropTypes.bool,
  showBack: PropTypes.bool,
}

const mapStateToProps = state => {
  return { language: state.language }
}

export default connect(mapStateToProps, null)(Navigation)
